import { useCallback, useEffect, useState } from "react";
import { useDataApi } from "../api/data";

export const useThingKeys = () => {
  const [thingPropertyKeys, setThingPropertyKeys] = useState<
    Record<string, string[]>
  >({});
  const [thingMetricKeys, setThingMetricKeys] = useState<
    Record<string, string[]>
  >({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const { getThingPropertyKeys, getThingMetricKeys } = useDataApi();

  const fetchKeys = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const [propertyKeys, metricKeys] = await Promise.all([
        getThingPropertyKeys(),
        getThingMetricKeys(),
      ]);

      setThingPropertyKeys(propertyKeys);
      setThingMetricKeys(metricKeys);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchKeys();
  }, [fetchKeys]);

  return {
    thingPropertyKeys,
    thingMetricKeys,
    isLoading,
    error,
    refetch: fetchKeys,
  };
};
