import React from "react";

interface TextFieldProps {
  label: string;
  value: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  error?: string;
  type?: "text" | "password" | "email" | "number" | "tel" | "url";
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  autoComplete?: string;
  name?: string;
  id?: string;
  className?: string;
}

const TextField = ({
  label,
  value,
  placeholder,
  onChange = () => {},
  disabled = false,
  error,
  type = "text",
  required = false,
  maxLength,
  minLength,
  pattern,
  autoComplete,
  name,
  id,
  className = "",
}: TextFieldProps) => (
  <div
    className={`p-3 rounded-lg outline outline-2 outline-offset-[-2px] outline-space90 flex-col justify-start items-center gap-0 w-full ${error ? "outline-red-500" : ""} ${className}`}
  >
    <div className="justify-start text-space70 text-[10px] font-normal">
      {label}
      {required && <span className="text-red-500 ml-1">*</span>}
    </div>
    <input
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      maxLength={maxLength}
      minLength={minLength}
      pattern={pattern}
      autoComplete={autoComplete}
      name={name}
      id={id}
      className={`w-full justify-start text-space50 text-sm font-medium leading-none bg-transparent border-none focus:outline-none disabled:opacity-50 ${error ? "text-red-500" : ""}`}
    />
    {error && <div className="text-red-500 text-xs mt-1">{error}</div>}
  </div>
);

export default TextField;
