import React, { useState } from "react";
import type { VariablesConfig } from "../../api/enterprise/monitors";
import Modal from "../uikit/modal";
import TextField from "../uikit/textField";

type MonitorVariableDetailProps = {
  variable: VariablesConfig;
  onDelete: () => void;
  onEdit: (updatedVariable: VariablesConfig) => void;
};

const MonitorVariableDetail = ({
  variable,
  onDelete,
  onEdit,
}: MonitorVariableDetailProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [editError, setEditError] = useState<string | null>(null);
  const [editedVariable, setEditedVariable] =
    useState<VariablesConfig>(variable);

  const handleDelete = async () => {
    setIsDeleting(true);
    setDeleteError(null);
    try {
      await onDelete();
      setIsDeleteModalOpen(false);
    } catch (error) {
      setDeleteError(error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleEdit = async () => {
    setIsEditing(true);
    setEditError(null);
    try {
      await onEdit(editedVariable);
      setIsEditModalOpen(false);
    } catch (error) {
      setEditError(error.message);
    } finally {
      setIsEditing(false);
    }
  };

  return (
    <>
      <div className="self-stretch p-4 bg-white rounded outline outline-1 outline-offset-[-1px] outline-gray95 flex flex-col justify-start items-start gap-2">
        <div className="self-stretch inline-flex justify-between items-center">
          <div className="flex-1 inline-flex flex-col justify-start items-start">
            <div className="self-stretch justify-start text-space70 text-[10px] font-normal">
              Variable Name
            </div>
            <div className="w-28 justify-start text-black text-xs font-normal font-mono leading-tight">
              {variable.name}
            </div>
          </div>
          <div className="flex-1 inline-flex flex-col justify-start items-start">
            <div className="self-stretch justify-start text-space70 text-[10px] font-normal">
              Type
            </div>
            <div className="justify-start text-black text-xs font-normal font-mono leading-tight">
              Boolean
            </div>
          </div>
        </div>
        <div className="self-stretch inline-flex justify-end items-end gap-2">
          <div className="flex-1 inline-flex flex-col justify-start items-start">
            <div className="self-stretch justify-start text-space70 text-[10px] font-normal">
              Variable Expression
            </div>
            <div className="justify-start text-black text-xs font-normal font-mono leading-tight">
              {variable.expression}
            </div>
          </div>
          <div className="h-5 flex justify-start items-end gap-2.5">
            <button
              type="button"
              className="self-stretch rounded flex justify-center items-center gap-2.5 cursor-pointer"
              onClick={() => setIsEditModalOpen(true)}
            >
              <div className="justify-start text-blue50 text-sm font-normal font-['SF_Pro']">
                􀈎
              </div>
            </button>
            <button
              type="button"
              className="self-stretch rounded flex justify-center items-center gap-2.5 cursor-pointer"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <div className="justify-start text-red50 text-sm font-normal font-['SF_Pro']">
                􀈑
              </div>
            </button>
          </div>
        </div>
      </div>

      <Modal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete Variable"
        error={deleteError ? { message: deleteError } : undefined}
        actions={{
          cancel: {
            label: "Cancel",
            onClick: () => setIsDeleteModalOpen(false),
          },
          confirm: {
            label: isDeleting ? "Deleting..." : "Delete Variable",
            onClick: handleDelete,
            disabled: isDeleting,
            variant: "danger",
          },
        }}
      >
        <div className="text-center text-sm text-gray-500 mt-4">
          Are you sure you want to delete the variable{" "}
          <span className="font-semibold">{variable.name}</span>? This action
          cannot be undone.
        </div>
      </Modal>

      <Modal
        open={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setEditError(null);
          setEditedVariable(variable);
        }}
        title="Edit Variable"
        error={editError ? { message: editError } : undefined}
        actions={{
          cancel: {
            label: "Cancel",
            onClick: () => {
              setIsEditModalOpen(false);
              setEditError(null);
              setEditedVariable(variable);
            },
          },
          confirm: {
            label: isEditing ? "Saving..." : "Save Changes",
            onClick: handleEdit,
            disabled: isEditing,
          },
        }}
      >
        <div className="mt-4 space-y-4">
          <TextField
            label="Variable Name"
            value={editedVariable.name}
            onChange={(value) =>
              setEditedVariable({ ...editedVariable, name: value })
            }
          />
          <TextField
            label="Variable Expression"
            value={editedVariable.expression}
            onChange={(value) =>
              setEditedVariable({
                ...editedVariable,
                expression: value,
              })
            }
          />
        </div>
      </Modal>
    </>
  );
};

export default MonitorVariableDetail;
