import type { Dayjs } from "dayjs";
import React from "react";
import type { Monitor, MonitorData } from "../../api/enterprise";
import MonitorAlertChart from "./MonitorAlertChart";
const MonitorGraph = ({
  lastUpdated,
  monitor,
  monitorData,
  onRefresh = () => {},
  disabled = false,
}: {
  lastUpdated: Dayjs | undefined;
  monitor: Monitor;
  monitorData: MonitorData[] | undefined;
  onRefresh: () => void;
  disabled?: boolean;
}) => {
  const latestEvent = monitorData?.[monitorData.length - 1];

  return (
    <div className="flex-1 px-4 inline-flex flex-col justify-start items-start gap-4">
      <div
        className={`self-stretch inline-flex justify-between items-center ${disabled ? "opacity-50" : ""}`}
      >
        <div className="flex-1 inline-flex flex-col justify-start items-start">
          <div className="self-stretch justify-start text-space70 text-[10px] font-normal">
            Monitor State
          </div>
          <div className="w-28 justify-start text-black text-xs font-normal leading-tight">
            {latestEvent?.monitorEvent.monitorState}
          </div>
        </div>
        <div className="flex-1 inline-flex flex-col justify-start items-start">
          <div className="self-stretch justify-start text-space70 text-[10px] font-normal">
            Monitor Data State
          </div>
          <div className="justify-start text-black text-xs font-normal leading-tight">
            {latestEvent?.monitorEvent.monitorDataState}
          </div>
        </div>
      </div>

      <div className={`w-full ${disabled ? "opacity-50" : ""}`}>
        {monitorData && (
          <MonitorAlertChart
            alertConfigs={monitor.alertConfigs}
            monitorData={monitorData}
          />
        )}
      </div>

      <div
        className={`self-stretch inline-flex justify-between items-center ${disabled ? "opacity-50" : ""}`}
      >
        <div className="opacity-50 justify-start text-black text-[10px] font-normal font-mono">
          Last updated {lastUpdated?.fromNow() ?? "never"}
        </div>
        <button
          type="button"
          className="flex justify-start items-center gap-1 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={onRefresh}
          disabled={!lastUpdated || disabled}
        >
          <div className="justify-start text-blue50 text-[10px] font-bold font-['SF_Pro']">
            􀅈
          </div>
          <div className="justify-start text-blue50 text-xs font-semibold uppercase">
            Refresh
          </div>
        </button>
      </div>

      {disabled && (
        <div className="self-stretch justify-start text-red50 text-xs font-normal">
          Monitor graph is disabled while edits are being made
        </div>
      )}
    </div>
  );
};

export default MonitorGraph;
