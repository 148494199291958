import type { ReactNode } from "react";

type MonitorSectionProps = {
  title: string;
  description?: string;
  children: ReactNode;
};

const MonitorSection = ({
  title,
  description,
  children,
}: MonitorSectionProps) => (
  <div className="flex w-full flex-col justify-start items-start gap-2 mt-4">
    <div className="flex flex-col justify-start items-start">
      <div className="justify-start text-space50 text-base font-medium leading-relaxed">
        {title}
      </div>
      {description && (
        <div className="justify-start text-space70 text-[10px] font-medium tracking-wide">
          {description}
        </div>
      )}
    </div>
    {children}
  </div>
);

export default MonitorSection;
