import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { Monitor } from "../api/enterprise/monitors";
import { useMonitorsApi } from "../api/enterprise/monitors";
import type { Thing } from "../api/ingestion/things";
import { useThingsApi } from "../api/ingestion/things";
import type { ErrorType } from "../api/shared";
import { useAuth } from "../context/AuthContext";
import { useThingKeys } from "./useThingKeys";

export const useMonitor = (thingId: string, monitorId: string) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { getMonitorForThing, deleteMonitorForThing, updateMonitorForThing } =
    useMonitorsApi();
  const { getThings } = useThingsApi();

  const [monitor, setMonitor] = useState<Monitor | null>(null);
  const [thing, setThing] = useState<Thing | null>(null);
  const [things, setThings] = useState<Thing[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [saveError, setSaveError] = useState<ErrorType | null>(null);
  const [deleteError, setDeleteError] = useState<ErrorType | null>(null);

  const {
    thingPropertyKeys,
    thingMetricKeys,
    isLoading: isLoadingKeys,
    error: keysError,
  } = useThingKeys();

  useEffect(() => {
    const fetchData = async () => {
      if (!thingId || !monitorId || !user?.partnerId) return;

      try {
        const [monitorData, thingsData] = await Promise.all([
          getMonitorForThing(thingId, monitorId, user.partnerId),
          getThings(),
        ]);

        setMonitor(monitorData);
        setThings(thingsData);
        const thingData = thingsData.find((t) => t.thingId === thingId);
        if (thingData) {
          setThing(thingData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [thingId, monitorId, user?.partnerId]);

  const handleSave = async () => {
    if (!thingId || !monitorId || !user?.partnerId || !monitor) return;

    setIsSaving(true);
    setSaveError(null);

    try {
      await updateMonitorForThing(thingId, monitorId, user.partnerId, monitor);
      setIsEditing(false);
    } catch (error) {
      setSaveError(error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (!thingId || !monitorId || !user?.partnerId) return;
    setIsDeleting(true);
    setDeleteError(null);

    try {
      await deleteMonitorForThing(thingId, monitorId, user.partnerId);
      navigate("/monitors");
    } catch (error) {
      setDeleteError(error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleUpdateMonitor = (updates: Partial<Monitor>) => {
    if (!monitor) return;
    setMonitor({ ...monitor, ...updates });
    setIsEditing(true);
  };

  return {
    monitor,
    thing,
    things,
    thingPropertyKeys,
    thingMetricKeys,
    isEditing,
    isSaving,
    isDeleting,
    saveError,
    deleteError,
    handleSave,
    handleDelete,
    handleUpdateMonitor,
    setIsEditing,
    setSaveError,
    setDeleteError,
  };
};
