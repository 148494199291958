import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { type Dayjs, dayjs } from "utils/dayjs";
import type { MonitorData } from "../../api/enterprise";
import type {
  AlertConfig,
  MetricsConfig,
  VariablesConfig,
} from "../../api/enterprise/monitors";
import { useMonitorsApi } from "../../api/enterprise/monitors";
import MonitorAlertDetail from "../../components/monitors/MonitorAlertDetail";
import MonitorGraph from "../../components/monitors/MonitorGraph";
import MonitorMetricDetail from "../../components/monitors/MonitorMetricDetail";
import MonitorSection from "../../components/monitors/MonitorSection";
import MonitorVariableDetail from "../../components/monitors/MonitorVariableDetail";
import {
  ContentLayout,
  Header,
  HeaderDivider,
  HeaderTitle,
  PageContent,
} from "../../components/pageLayout";
import ButtonComponent from "../../components/uikit/button";
import Modal from "../../components/uikit/modal";
import SelectField from "../../components/uikit/selectField";
import TextField from "../../components/uikit/textField";
import { useAuth } from "../../context/AuthContext";
import { useMonitor } from "../../hooks/useMonitor";

const ThingMonitorDetailPage = () => {
  const { thingId, monitorId } = useParams();

  const { user } = useAuth();
  const { getMonitorDataForPlace } = useMonitorsApi();

  const {
    monitor,
    thing,
    things,
    thingPropertyKeys,
    thingMetricKeys,
    isEditing,
    isSaving,
    isDeleting,
    saveError,
    deleteError,
    handleSave,
    handleDelete,
    handleUpdateMonitor,
    setIsEditing,
    setSaveError,
    setDeleteError,
  } = useMonitor(thingId || "", monitorId || "");

  // State for monitor data
  const [monitorData, setMonitorData] = useState<MonitorData | null>(null);
  const [lastLoaded, setLastLoaded] = useState<Dayjs | undefined>(undefined);

  // biome-ignore lint/correctness/useExhaustiveDependencies: getMonitorDataForPlace is not a dependency
  useEffect(() => {
    const fetchMonitorData = async () => {
      if (monitor) {
        const data = await getMonitorDataForPlace(
          monitor.placeId,
          "site",
          monitor.resourceId,
          monitor.monitorId,
          user?.partnerId,
          dayjs().subtract(1, "day"),
          dayjs(),
        );
        setMonitorData(data);
        setLastLoaded(dayjs());
      }
    };
    fetchMonitorData();
  }, [monitor, user?.partnerId]);

  // Add new state for add modals
  const [isAddMetricModalOpen, setIsAddMetricModalOpen] = useState(false);
  const [isAddVariableModalOpen, setIsAddVariableModalOpen] = useState(false);
  const [isAddAlertModalOpen, setIsAddAlertModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [newMetric, setNewMetric] = useState<MetricsConfig>({
    metricName: "",
    missingDataBehavior: "NO_INFILL",
  });
  const [newVariable, setNewVariable] = useState<VariablesConfig>({
    name: "",
    expression: "",
    type: "BOOLEAN",
  });
  const [newAlert, setNewAlert] = useState<AlertConfig>({
    alertName: "",
    message: "",
    condition: "",
    severity: "0",
    integrationIds: [],
    state: "HEALTHY",
    metadata: {},
  });
  const [addError, setAddError] = useState<string | null>(null);

  if (!monitor || !thing) {
    return <div>Loading...</div>;
  }

  const resourceTypeKey = monitor.resourceType.split(".")[1];
  const availableProperties = thingPropertyKeys[resourceTypeKey] || [];
  const availableMetrics = thingMetricKeys[resourceTypeKey] || [];
  const thingTypes = Object.keys(thingPropertyKeys);

  // HANDLERS
  const handleNameChange = (name: string) => {
    handleUpdateMonitor({ monitorName: name });
  };

  const handleResourceTypeChange = (resourceType: string) => {
    handleUpdateMonitor({ resourceType: `THING.${resourceType}` });
  };

  const handleResourceIdChange = (resourceId: string) => {
    handleUpdateMonitor({ resourceId });
  };

  const handleLookbackChange = (lookback: string) => {
    handleUpdateMonitor({ lookbackPeriod: lookback });
  };

  const handleFrequencyChange = (frequency: string) => {
    handleUpdateMonitor({ frequency });
  };

  const handleDeleteMetric = async (metricName: string) => {
    handleUpdateMonitor({
      metrics: monitor.metrics.filter((m) => m.metricName !== metricName),
    });
  };

  const handleDeleteVariable = async (variableName: string) => {
    handleUpdateMonitor({
      variables: monitor.variables.filter((v) => v.name !== variableName),
    });
  };

  const handleDeleteAlert = async (alertName: string) => {
    handleUpdateMonitor({
      alertConfigs: monitor.alertConfigs.filter(
        (a) => a.alertName !== alertName,
      ),
    });
  };

  const handleEditMetric = async (updatedMetric: MetricsConfig) => {
    handleUpdateMonitor({
      metrics: monitor.metrics.map((m) =>
        m.metricName === updatedMetric.metricName ? updatedMetric : m,
      ),
    });
  };

  const handleEditVariable = async (updatedVariable: VariablesConfig) => {
    handleUpdateMonitor({
      variables: monitor.variables.map((v) =>
        v.name === updatedVariable.name ? updatedVariable : v,
      ),
    });
  };

  const handleEditAlert = async (updatedAlert: AlertConfig) => {
    handleUpdateMonitor({
      alertConfigs: monitor.alertConfigs.map((a) =>
        a.alertName === updatedAlert.alertName ? updatedAlert : a,
      ),
    });
  };

  const handleAddMetric = async () => {
    try {
      handleUpdateMonitor({
        metrics: [...monitor.metrics, newMetric],
      });
      setIsAddMetricModalOpen(false);
      setNewMetric({
        metricName: "",
        missingDataBehavior: "NO_INFILL",
      });
    } catch (error) {
      setAddError(error.message);
    }
  };

  const handleAddVariable = async () => {
    try {
      handleUpdateMonitor({
        variables: [...monitor.variables, newVariable],
      });
      setIsAddVariableModalOpen(false);
      setNewVariable({
        name: "",
        expression: "",
        type: "BOOLEAN",
      });
    } catch (error) {
      setAddError(error.message);
    }
  };

  const handleAddAlert = async () => {
    try {
      handleUpdateMonitor({
        alertConfigs: [...monitor.alertConfigs, newAlert],
      });
      setIsAddAlertModalOpen(false);
      setNewAlert({
        alertName: "",
        message: "",
        condition: "",
        severity: "0",
        integrationIds: [],
        state: "HEALTHY",
        metadata: {},
      });
    } catch (error) {
      setAddError(error.message);
    }
  };

  return (
    <ContentLayout>
      <Header>
        <HeaderTitle
          category="Monitors"
          categoryLink="/monitors"
          pageTitle={monitor?.monitorName}
          detail={monitor?.monitorId}
        />
        {isEditing && (
          <ButtonComponent.Pill
            variant="filled"
            buttonStyle="primary"
            onClick={handleSave}
            disabled={isSaving}
          >
            Save Changes
          </ButtonComponent.Pill>
        )}
      </Header>
      <HeaderDivider />
      <PageContent>
        {/* Monitor Details */}
        <MonitorSection title="Monitor Details">
          <div className="flex flex-row w-full gap-4">
            <div className="flex-1 inline-flex flex-col justify-start items-start gap-2">
              <TextField
                label="Monitor Name"
                value={monitor.monitorName}
                onChange={handleNameChange}
              />
              <SelectField
                label="Attached Resource Type"
                value={resourceTypeKey}
                options={thingTypes.map((type) => ({
                  value: type,
                  label: type,
                }))}
                onChange={handleResourceTypeChange}
              />
              <SelectField
                label="Attached Resource ID"
                value={monitor.resourceId}
                options={things
                  .filter((t) => t.thingType === resourceTypeKey)
                  .map((t) => ({
                    value: t.thingId,
                    label: t.thingName,
                  }))}
                onChange={handleResourceIdChange}
              />
              <SelectField
                label="Lookback"
                value="10m"
                options={[
                  { value: "1m", label: "1 minute" },
                  { value: "2m", label: "2 minutes" },
                  { value: "5m", label: "5 minutes" },
                  { value: "10m", label: "10 minutes" },
                  { value: "15m", label: "15 minutes" },
                  { value: "30m", label: "30 minutes" },
                  { value: "1h", label: "1 hour" },
                  { value: "2h", label: "2 hours" },
                  { value: "4h", label: "4 hours" },
                  { value: "8h", label: "8 hours" },
                  { value: "12h", label: "12 hours" },
                  { value: "1d", label: "1 day" },
                ]}
                onChange={handleLookbackChange}
              />
              <SelectField
                label="Frequency"
                value="2m"
                options={[
                  { value: "1m", label: "1 minute" },
                  { value: "2m", label: "2 minutes" },
                  { value: "5m", label: "5 minutes" },
                  { value: "10m", label: "10 minutes" },
                  { value: "15m", label: "15 minutes" },
                  { value: "30m", label: "30 minutes" },
                  { value: "1h", label: "1 hour" },
                  { value: "2h", label: "2 hours" },
                  { value: "4h", label: "4 hours" },
                  { value: "8h", label: "8 hours" },
                  { value: "12h", label: "12 hours" },
                  { value: "1d", label: "1 day" },
                ]}
                onChange={handleFrequencyChange}
              />
            </div>
            <MonitorGraph
              lastUpdated={lastLoaded}
              monitor={monitor}
              monitorData={monitorData}
              onRefresh={() => {}}
              disabled={isEditing}
            />
          </div>
        </MonitorSection>

        {/* Alerts */}
        <MonitorSection
          title="Alerts"
          description="To setup an alert, first choose a source metric then use the variable to setup an alert. You can optionally define transformations on metrics and properties to more easily capture important thresholds and re-use them across different alerts."
        >
          <div className="pb-3 flex flex-col w-full justify-center items-leading gap-2">
            <div className="justify-start text-space60 text-xs font-semibold uppercase">
              Available Properties
            </div>
            <div className="grid grid-cols-3 lg:grid-cols-5 gap-2 w-full">
              {availableProperties.map((key) => (
                <div
                  key={key}
                  className="inline-flex justify-start items-start gap-2"
                >
                  <div className="justify-start text-space20 text-xs font-normal font-mono">
                    {key}
                  </div>
                </div>
              ))}
            </div>

            <div className="justify-start text-space60 text-xs font-semibold uppercase">
              Available Metrics
            </div>
            <div className="grid grid-cols-3 lg:grid-cols-5 gap-2 w-full">
              {availableMetrics.map((key) => (
                <div
                  key={key}
                  className="inline-flex justify-start items-start gap-2"
                >
                  <div className="justify-start text-space20 text-xs font-normal font-mono">
                    {key}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </MonitorSection>

        <div className="inline-flex justify-start items-start gap-4">
          <div className="flex-1 inline-flex flex-col justify-start items-start gap-3">
            <div className="self-stretch flex flex-col justify-start items-start gap-0.5">
              <div className="self-stretch justify-start text-space60 text-xs font-semibold uppercase">
                Step 1: Choose Metrics to Alert On
              </div>
              <div className="self-stretch justify-start text-space70 text-[10px] font-medium tracking-wide">
                Metrics are data from your connected resources
              </div>
            </div>
            {monitor.metrics.map((metric) => (
              <MonitorMetricDetail
                key={metric.metricName}
                metric={metric}
                onDelete={() => handleDeleteMetric(metric.metricName)}
                onEdit={handleEditMetric}
              />
            ))}
            <button
              type="button"
              className="px-3.5 py-2 bg-blue50 rounded-[38px] inline-flex justify-end items-center gap-1 cursor-pointer"
              onClick={() => setIsAddMetricModalOpen(true)}
            >
              <div className="justify-start text-white text-xs font-medium leading-none">
                Add Metric
              </div>
            </button>
          </div>
          <div className="flex-1 inline-flex flex-col justify-start items-start gap-3">
            <div className="self-stretch flex flex-col justify-start items-start gap-0.5">
              <div className="self-stretch justify-start text-space60 text-xs font-semibold uppercase">
                Step 2: Define Variables
              </div>
              <div className="self-stretch justify-start text-space70 text-[10px] font-medium tracking-wide">
                Define optional variables with metrics and property
                transformations to simplify alert conditions
              </div>
            </div>
            {monitor.variables.map((variable) => (
              <MonitorVariableDetail
                key={variable.name}
                variable={variable}
                onDelete={() => handleDeleteVariable(variable.name)}
                onEdit={handleEditVariable}
              />
            ))}
            <button
              type="button"
              className="px-3.5 py-2 bg-blue50 rounded-[38px] inline-flex justify-end items-center gap-1 cursor-pointer"
              onClick={() => setIsAddVariableModalOpen(true)}
            >
              <div className="justify-start text-white text-xs font-medium leading-none">
                Add Variable
              </div>
            </button>
          </div>
          <div className="flex-1 inline-flex flex-col justify-start items-start gap-3">
            <div className="self-stretch flex flex-col justify-start items-start gap-0.5">
              <div className="self-stretch justify-start text-space60 text-xs font-semibold uppercase">
                Step 4: Setup Alert Conditions
              </div>
              <div className="self-stretch justify-start text-space70 text-[10px] font-medium tracking-wide">
                Add conditions to trigger alerts and specify their severities
              </div>
            </div>
            {monitor.alertConfigs.map((alert) => (
              <MonitorAlertDetail
                key={alert.alertName}
                alert={alert}
                onDelete={() => handleDeleteAlert(alert.alertName)}
                onEdit={handleEditAlert}
              />
            ))}
            <button
              type="button"
              className="px-3.5 py-2 bg-blue50 rounded-[38px] inline-flex justify-end items-center gap-1 cursor-pointer"
              onClick={() => setIsAddAlertModalOpen(true)}
            >
              <div className="justify-start text-white text-xs font-medium leading-none">
                Add Alert Condition
              </div>
            </button>
          </div>
        </div>

        {/* Monitor Info */}
        <div className="opacity-50 flex flex-col justify-start items-start">
          <div className="justify-start text-black text-[10px] font-normal font-mono">
            Monitor ID: {monitor.monitorId}
          </div>
          <div className="justify-start text-black text-[10px] font-normal font-mono">
            Monitor Language: {monitor.monitorLanguage}
          </div>
          <div className="justify-start text-black text-[10px] font-normal font-mono">
            Version: {monitor.version}
          </div>
          <div className="justify-start text-black text-[10px] font-normal font-mono">
            Metadata: {JSON.stringify(monitor.metadata)}
          </div>
          <div className="justify-start text-black text-[10px] font-normal font-mono">
            Created At: {new Date(monitor.createdAt).toLocaleString()}
          </div>
          <div className="justify-start text-black text-[10px] font-normal font-mono">
            Created By: {monitor.createdBy}
          </div>
        </div>

        {/* Delete Button */}
        <button
          type="button"
          className="px-3.5 py-2 bg-red50 rounded-[38px] inline-flex justify-end items-center gap-1 cursor-pointer"
          onClick={() => setIsDeleteModalOpen(true)}
        >
          <div className="justify-start text-white text-xs font-['SF_Pro']">
            􀈑
          </div>
          <div className="justify-start text-white text-xs font-medium leading-none">
            Delete Monitor
          </div>
        </button>

        {/* Delete Modal */}
        <Modal
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          title="Delete Monitor"
          error={
            deleteError ? { message: JSON.stringify(deleteError) } : undefined
          }
          actions={{
            cancel: {
              label: "Cancel",
              onClick: () => setIsDeleteModalOpen(false),
            },
            confirm: {
              label: isDeleting ? "Deleting..." : "Delete Monitor",
              onClick: handleDelete,
              disabled: isDeleting,
              variant: "danger",
            },
          }}
        >
          <div className="text-center text-sm text-gray-500 mt-4">
            Are you sure you want to delete{" "}
            <span className="font-semibold">{monitor?.monitorName}</span>? This
            action cannot be undone.
          </div>
        </Modal>

        {/* Save Error Modal */}
        <Modal
          open={saveError !== null}
          onClose={() => setSaveError(null)}
          title="Error Saving Monitor"
          error={saveError ? { message: JSON.stringify(saveError) } : undefined}
          actions={{
            cancel: {
              label: "Close",
              onClick: () => setSaveError(null),
            },
          }}
        />

        {/* Add Metric Modal */}
        <Modal
          open={isAddMetricModalOpen}
          onClose={() => {
            setIsAddMetricModalOpen(false);
            setAddError(null);
          }}
          title="Add Metric"
          error={addError ? { message: addError } : undefined}
          actions={{
            cancel: {
              label: "Cancel",
              onClick: () => {
                setIsAddMetricModalOpen(false);
                setAddError(null);
              },
            },
            confirm: {
              label: "Add Metric",
              onClick: handleAddMetric,
              disabled: !newMetric.metricName,
            },
          }}
        >
          <div className="mt-4 space-y-4">
            <TextField
              label="Metric Name"
              value={newMetric.metricName}
              onChange={(value) =>
                setNewMetric({ ...newMetric, metricName: value })
              }
            />
            <SelectField
              label="Missing Data Behavior"
              value={newMetric.missingDataBehavior}
              options={[
                { value: "NO_INFILL", label: "No Infill" },
                { value: "INFILL_ZERO", label: "Infill Zero" },
                { value: "INFILL_LAST_VALUE", label: "Infill Last Value" },
              ]}
              onChange={(value) =>
                setNewMetric({
                  ...newMetric,
                  missingDataBehavior: value,
                })
              }
            />
          </div>
        </Modal>

        {/* Add Variable Modal */}
        <Modal
          open={isAddVariableModalOpen}
          onClose={() => {
            setIsAddVariableModalOpen(false);
            setAddError(null);
          }}
          title="Add Variable"
          error={addError ? { message: addError } : undefined}
          actions={{
            cancel: {
              label: "Cancel",
              onClick: () => {
                setIsAddVariableModalOpen(false);
                setAddError(null);
              },
            },
            confirm: {
              label: "Add Variable",
              onClick: handleAddVariable,
              disabled: !newVariable.name || !newVariable.expression,
            },
          }}
        >
          <div className="mt-4 space-y-4">
            <TextField
              label="Variable Name"
              value={newVariable.name}
              onChange={(value) =>
                setNewVariable({ ...newVariable, name: value })
              }
            />
            <TextField
              label="Variable Expression"
              value={newVariable.expression}
              onChange={(value) =>
                setNewVariable({
                  ...newVariable,
                  expression: value,
                })
              }
            />
          </div>
        </Modal>

        {/* Add Alert Modal */}
        <Modal
          open={isAddAlertModalOpen}
          onClose={() => {
            setIsAddAlertModalOpen(false);
            setAddError(null);
          }}
          title="Add Alert Condition"
          error={addError ? { message: addError } : undefined}
          actions={{
            cancel: {
              label: "Cancel",
              onClick: () => {
                setIsAddAlertModalOpen(false);
                setAddError(null);
              },
            },
            confirm: {
              label: "Add Alert",
              onClick: handleAddAlert,
              disabled:
                !newAlert.alertName || !newAlert.message || !newAlert.condition,
            },
          }}
        >
          <div className="mt-4 space-y-4">
            <TextField
              label="Alert Name"
              value={newAlert.alertName}
              onChange={(value) =>
                setNewAlert({ ...newAlert, alertName: value })
              }
            />
            <TextField
              label="Alert Message"
              value={newAlert.message}
              onChange={(value) => setNewAlert({ ...newAlert, message: value })}
            />
            <TextField
              label="Condition"
              value={newAlert.condition}
              onChange={(value) =>
                setNewAlert({ ...newAlert, condition: value })
              }
            />
            <SelectField
              label="Severity"
              value={newAlert.severity}
              options={[
                { value: "0", label: "0 - Low" },
                { value: "1", label: "1" },
                { value: "2", label: "2" },
                { value: "3", label: "3" },
                { value: "4", label: "4" },
                { value: "5", label: "5 - Medium" },
                { value: "6", label: "6" },
                { value: "7", label: "7" },
                { value: "8", label: "8" },
                { value: "9", label: "9 - High" },
              ]}
              onChange={(value) =>
                setNewAlert({
                  ...newAlert,
                  severity: value,
                })
              }
            />
          </div>
        </Modal>
      </PageContent>
    </ContentLayout>
  );
};

export { ThingMonitorDetailPage };
