import React, { useState } from "react";
import type { MetricsConfig } from "../../api/enterprise/monitors";
import Modal from "../uikit/modal";
import SelectField from "../uikit/selectField";
import TextField from "../uikit/textField";

type MonitorMetricDetailProps = {
  metric: MetricsConfig;
  onDelete: () => void;
  onEdit: (updatedMetric: MetricsConfig) => void;
};

const MonitorMetricDetail = ({
  metric,
  onDelete,
  onEdit,
}: MonitorMetricDetailProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [editError, setEditError] = useState<string | null>(null);
  const [editedMetric, setEditedMetric] = useState<MetricsConfig>(metric);

  const handleDelete = async () => {
    setIsDeleting(true);
    setDeleteError(null);
    try {
      await onDelete();
      setIsDeleteModalOpen(false);
    } catch (error) {
      setDeleteError(error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleEdit = async () => {
    setIsEditing(true);
    setEditError(null);
    try {
      await onEdit(editedMetric);
      setIsEditModalOpen(false);
    } catch (error) {
      setEditError(error.message);
    } finally {
      setIsEditing(false);
    }
  };

  return (
    <>
      <div className="self-stretch p-4 bg-white rounded outline outline-1 outline-offset-[-1px] outline-gray95 flex flex-col justify-start items-start gap-2">
        <div className="self-stretch inline-flex justify-between items-center">
          <div className="flex-1 inline-flex flex-col justify-start items-start">
            <div className="self-stretch justify-start text-space70 text-[10px] font-normal">
              Metric Name
            </div>
            <div className="justify-start text-black text-xs font-normal leading-tight">
              {metric.metricName}
            </div>
          </div>
        </div>
        <div className="self-stretch inline-flex justify-end items-end gap-2">
          <div className="flex-1 inline-flex flex-col justify-start items-start">
            <div className="self-stretch justify-start text-space70 text-[10px] font-normal">
              Missing Data Behavior
            </div>
            <div className="justify-start text-black text-xs font-normal leading-tight">
              {metric.missingDataBehavior}
            </div>
          </div>
          <div className="h-5 flex justify-start items-end gap-2.5">
            <button
              type="button"
              className="self-stretch rounded flex justify-center items-center gap-2.5 cursor-pointer"
              onClick={() => setIsEditModalOpen(true)}
            >
              <div className="justify-start text-blue50 text-sm font-normal font-['SF_Pro']">
                􀈎
              </div>
            </button>
            <button
              type="button"
              className="self-stretch rounded flex justify-center items-center gap-2.5 cursor-pointer"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <div className="justify-start text-red50 text-sm font-normal font-['SF_Pro']">
                􀈑
              </div>
            </button>
          </div>
        </div>
      </div>

      <Modal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete Metric"
        error={deleteError ? { message: deleteError } : undefined}
        actions={{
          cancel: {
            label: "Cancel",
            onClick: () => setIsDeleteModalOpen(false),
          },
          confirm: {
            label: isDeleting ? "Deleting..." : "Delete Metric",
            onClick: handleDelete,
            disabled: isDeleting,
            variant: "danger",
          },
        }}
      >
        <div className="text-center text-sm text-gray-500 mt-4">
          Are you sure you want to delete the metric{" "}
          <span className="font-semibold">{metric.metricName}</span>? This
          action cannot be undone.
        </div>
      </Modal>

      <Modal
        open={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setEditError(null);
          setEditedMetric(metric);
        }}
        title="Edit Metric"
        error={editError ? { message: editError } : undefined}
        actions={{
          cancel: {
            label: "Cancel",
            onClick: () => {
              setIsEditModalOpen(false);
              setEditError(null);
              setEditedMetric(metric);
            },
          },
          confirm: {
            label: isEditing ? "Saving..." : "Save Changes",
            onClick: handleEdit,
            disabled: isEditing,
          },
        }}
      >
        <div className="mt-4 space-y-4">
          <TextField
            label="Metric Name"
            value={editedMetric.metricName}
            onChange={(value) =>
              setEditedMetric({
                ...editedMetric,
                metricName: value,
              })
            }
          />
          <SelectField
            label="Missing Data Behavior"
            value={editedMetric.missingDataBehavior}
            options={[
              { value: "NO_INFILL", label: "No Infill" },
              { value: "INFILL_ZERO", label: "Infill Zero" },
              { value: "INFILL_LAST_VALUE", label: "Infill Last Value" },
            ]}
            onChange={(value) =>
              setEditedMetric({
                ...editedMetric,
                missingDataBehavior:
                  value as MetricsConfig["missingDataBehavior"],
              })
            }
          />
        </div>
      </Modal>
    </>
  );
};

export default MonitorMetricDetail;
