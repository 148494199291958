import { useAuth } from "context/AuthContext";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { MonitorRequiredFields } from "../../api/enterprise/monitors";
import { useMonitorsApi } from "../../api/enterprise/monitors";
import type { Thing } from "../../api/ingestion/things";
import { useThingsApi } from "../../api/ingestion/things";
import type { ErrorType } from "../../api/shared";
import { useThingKeys } from "../../hooks/useThingKeys";
import Modal from "../uikit/modal";
import SelectField from "../uikit/selectField";
import TextField from "../uikit/textField";

type CreateMonitorModalProps = {
  open: boolean;
  onClose: () => void;
};

const CreateMonitorModal = ({ open, onClose }: CreateMonitorModalProps) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { createMonitorForThing } = useMonitorsApi();

  const [error, setError] = useState<ErrorType | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const [things, setThings] = useState<Thing[]>([]);
  const [thingTypes, setThingTypes] = useState<string[]>([]);

  const { getThings, getThingTypes } = useThingsApi();

  const { thingMetricKeys } = useThingKeys();

  useEffect(() => {
    const fetchThings = async () => {
      getThings().then((things) => setThings(things));
      getThingTypes().then((thingTypes) => setThingTypes(thingTypes));
    };

    fetchThings();
  }, []);

  const [monitor, setMonitor] = useState<MonitorRequiredFields>({
    partnerId: user?.partnerId,
    placeId: "",
    resourceType: "",
    resourceId: "",

    monitorName: "",
    monitorLanguage: "DYNAMIC_EXPRESSO",
    frequency: "2m",
    lookbackPeriod: "10m",
    metrics: [],
    variables: [],
    alertConfigs: [],
    metadata: {},
  });

  const trimmedResourceType = monitor.resourceType.split(".")[1];

  const handleCreate = async () => {
    if (!user?.partnerId) return;

    setIsCreating(true);
    setError(null);

    try {
      const newMonitor = await createMonitorForThing(
        monitor.resourceId,
        user.partnerId,
        monitor,
      );
      navigate(`/monitors/${monitor.resourceId}/${newMonitor.monitorId}`);
      onClose();
    } catch (err) {
      setError(err as ErrorType);
    } finally {
      setIsCreating(false);
    }
  };

  const handleThingTypeChange = (type: string) => {
    setMonitor({ ...monitor, resourceType: `THING.${type}` });
  };

  const handleThingIdChange = (id: string) => {
    const tempMetric = thingMetricKeys[trimmedResourceType][6];
    setMonitor({
      ...monitor,
      resourceId: id,
      metrics: [
        {
          metricName: tempMetric,
          missingDataBehavior: "NO_INFILL",
        },
      ],
    });
  };

  const handleModalClose = () => {
    if (error) {
      setError(null);
    } else {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      title="Create New Monitor"
      error={
        error
          ? { message: error.title, message: JSON.stringify(error) }
          : undefined
      }
      actions={{
        cancel: {
          label: "Cancel",
          onClick: onClose,
        },
        confirm: {
          label: isCreating ? "Creating..." : "Create Monitor",
          onClick: handleCreate,
          disabled: isCreating || !monitor.monitorName || !monitor.resourceId,
        },
      }}
    >
      <div className="mt-4 space-y-4">
        <TextField
          label="Monitor Name"
          value={monitor.monitorName}
          onChange={(value) => setMonitor({ ...monitor, monitorName: value })}
        />
        <SelectField
          label="Thing Type"
          value={trimmedResourceType}
          options={[
            ...thingTypes.map((type) => ({
              value: type,
              label: type,
            })),
          ]}
          onChange={handleThingTypeChange}
        />
        <SelectField
          label="Thing ID"
          value={monitor.resourceId}
          options={[
            ...things
              .filter((thing) => thing.thingType === trimmedResourceType)
              .map((thing) => ({
                value: thing.thingId,
                label: thing.thingName,
              })),
          ]}
          onChange={handleThingIdChange}
          disabled={!trimmedResourceType}
        />
        <SelectField
          label="Frequency"
          value={monitor.frequency}
          options={[
            { value: "1m", label: "1 minute" },
            { value: "2m", label: "2 minutes" },
            { value: "5m", label: "5 minutes" },
            { value: "10m", label: "10 minutes" },
            { value: "15m", label: "15 minutes" },
            { value: "30m", label: "30 minutes" },
            { value: "1h", label: "1 hour" },
            { value: "2h", label: "2 hours" },
            { value: "4h", label: "4 hours" },
            { value: "8h", label: "8 hours" },
            { value: "12h", label: "12 hours" },
            { value: "1d", label: "1 day" },
          ]}
          onChange={(value) => setMonitor({ ...monitor, frequency: value })}
        />
        <SelectField
          label="Lookback Period"
          value={monitor.lookbackPeriod}
          options={[
            { value: "1m", label: "1 minute" },
            { value: "2m", label: "2 minutes" },
            { value: "5m", label: "5 minutes" },
            { value: "10m", label: "10 minutes" },
            { value: "15m", label: "15 minutes" },
            { value: "30m", label: "30 minutes" },
            { value: "1h", label: "1 hour" },
            { value: "2h", label: "2 hours" },
            { value: "4h", label: "4 hours" },
            { value: "8h", label: "8 hours" },
            { value: "12h", label: "12 hours" },
            { value: "1d", label: "1 day" },
          ]}
          onChange={(value) =>
            setMonitor({ ...monitor, lookbackPeriod: value })
          }
        />
      </div>
    </Modal>
  );
};

export default CreateMonitorModal;
