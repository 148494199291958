import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import type { ReactNode } from "react";

type ModalProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  error?: {
    title?: string;
    message: string | ReactNode;
  };
  actions?: {
    cancel?: {
      label: string;
      onClick: () => void;
    };
    confirm?: {
      label: string;
      onClick: () => void;
      disabled?: boolean;
      variant?: "primary" | "danger";
    };
  };
};

const Modal = ({
  open,
  onClose,
  title,
  children,
  error,
  actions,
}: ModalProps) => (
  <Dialog open={open} onClose={onClose} className="relative z-10">
    <DialogBackdrop
      transition
      className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
    />

    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <DialogPanel
          transition
          className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
        >
          {error ? (
            <div className="flex flex-col gap-4 pt-4">
              <div className="text-center font-semibold text-md text-red40">
                {error.title || "Error"}
              </div>
              <div className="text-center text-sm text-red40">
                {error.message}
              </div>
              <button
                type="button"
                onClick={onClose}
                className="inline-flex justify-center rounded-md bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-200"
              >
                Close
              </button>
            </div>
          ) : (
            <>
              <div>
                <DialogTitle
                  as="h3"
                  className="text-center text-base font-semibold leading-6 text-gray-900"
                >
                  {title}
                </DialogTitle>
                {children}
              </div>
              {actions && (
                <div className="mt-5 flex justify-end gap-3">
                  {actions.cancel && (
                    <button
                      type="button"
                      onClick={actions.cancel.onClick}
                      className="inline-flex justify-center rounded-md bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-200"
                    >
                      {actions.cancel.label}
                    </button>
                  )}
                  {actions.confirm && (
                    <button
                      type="button"
                      onClick={actions.confirm.onClick}
                      disabled={actions.confirm.disabled}
                      className={`inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                        actions.confirm.variant === "danger"
                          ? "bg-red50 hover:bg-red40 focus-visible:outline-red50"
                          : "bg-blue50 hover:bg-blue40 focus-visible:outline-blue50"
                      } disabled:bg-gray-400`}
                    >
                      {actions.confirm.label}
                    </button>
                  )}
                </div>
              )}
            </>
          )}
        </DialogPanel>
      </div>
    </div>
  </Dialog>
);

export default Modal;
