import React, { useState } from "react";
import type { AlertConfig } from "../../api/enterprise/monitors";
import Modal from "../uikit/modal";
import SelectField from "../uikit/selectField";
import TextField from "../uikit/textField";

type MonitorAlertDetailProps = {
  alert: AlertConfig;
  onDelete: () => void;
  onEdit: (updatedAlert: AlertConfig) => void;
};

const MonitorAlertDetail = ({
  alert,
  onDelete,
  onEdit,
}: MonitorAlertDetailProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [editError, setEditError] = useState<string | null>(null);
  const [editedAlert, setEditedAlert] = useState<AlertConfig>(alert);

  const handleDelete = async () => {
    setIsDeleting(true);
    setDeleteError(null);
    try {
      await onDelete();
      setIsDeleteModalOpen(false);
    } catch (error) {
      setDeleteError(error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleEdit = async () => {
    setIsEditing(true);
    setEditError(null);
    try {
      await onEdit(editedAlert);
      setIsEditModalOpen(false);
    } catch (error) {
      setEditError(error.message);
    } finally {
      setIsEditing(false);
    }
  };

  return (
    <>
      <div className="self-stretch p-4 bg-white rounded outline outline-1 outline-offset-[-1px] outline-gray95 flex flex-col justify-start items-start gap-2">
        <div className="self-stretch inline-flex justify-between items-center">
          <div className="flex-1 inline-flex flex-col justify-start items-start">
            <div className="self-stretch justify-start text-space70 text-[10px] font-normal">
              Alert Name
            </div>
            <div className="w-28 justify-start text-black text-xs font-normal font-mono leading-tight">
              {alert.alertName}
            </div>
          </div>
          <div className="flex-1 inline-flex flex-col justify-start items-start">
            <div className="self-stretch justify-start text-space70 text-[10px] font-normal">
              Condition
            </div>
            <div className="justify-start text-black text-xs font-normal font-mono leading-tight">
              {alert.condition}
            </div>
          </div>
        </div>
        <div className="self-stretch inline-flex justify-end items-end gap-2">
          <div className="flex-1 inline-flex flex-col justify-start items-start">
            <div className="self-stretch justify-start text-space70 text-[10px] font-normal">
              Severity
            </div>
            <div className="justify-start text-black text-xs font-normal font-mono leading-tight">
              {alert.severity}
            </div>
          </div>
          <div className="h-5 flex justify-start items-end gap-2.5">
            <button
              type="button"
              className="self-stretch rounded flex justify-center items-center gap-2.5 cursor-pointer"
              onClick={() => setIsEditModalOpen(true)}
            >
              <div className="justify-start text-blue50 text-sm font-normal font-['SF_Pro']">
                􀈎
              </div>
            </button>
            <button
              type="button"
              className="self-stretch rounded flex justify-center items-center gap-2.5 cursor-pointer"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <div className="justify-start text-red50 text-sm font-normal font-['SF_Pro']">
                􀈑
              </div>
            </button>
          </div>
        </div>
      </div>

      <Modal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete Alert"
        error={deleteError ? { message: deleteError } : undefined}
        actions={{
          cancel: {
            label: "Cancel",
            onClick: () => setIsDeleteModalOpen(false),
          },
          confirm: {
            label: isDeleting ? "Deleting..." : "Delete Alert",
            onClick: handleDelete,
            disabled: isDeleting,
            variant: "danger",
          },
        }}
      >
        <div className="text-center text-sm text-gray-500 mt-4">
          Are you sure you want to delete the alert{" "}
          <span className="font-semibold">{alert.alertName}</span>? This action
          cannot be undone.
        </div>
      </Modal>

      <Modal
        open={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setEditError(null);
          setEditedAlert(alert);
        }}
        title="Edit Alert"
        error={editError ? { message: editError } : undefined}
        actions={{
          cancel: {
            label: "Cancel",
            onClick: () => {
              setIsEditModalOpen(false);
              setEditError(null);
              setEditedAlert(alert);
            },
          },
          confirm: {
            label: isEditing ? "Saving..." : "Save Changes",
            onClick: handleEdit,
            disabled: isEditing,
          },
        }}
      >
        <div className="mt-4 space-y-4">
          <TextField
            label="Alert Name"
            value={editedAlert.alertName}
            onChange={(value) =>
              setEditedAlert({ ...editedAlert, alertName: value })
            }
          />
          <TextField
            label="Alert Message"
            value={editedAlert.message}
            onChange={(value) =>
              setEditedAlert({ ...editedAlert, message: value })
            }
          />
          <TextField
            label="Condition"
            value={editedAlert.condition}
            onChange={(value) =>
              setEditedAlert({
                ...editedAlert,
                condition: value,
              })
            }
          />
          <SelectField
            label="Severity"
            value={editedAlert.severity}
            onChange={(value) =>
              setEditedAlert({
                ...editedAlert,
                severity: value,
              })
            }
            options={[
              { value: "0", label: "0 - Low" },
              { value: "1", label: "1" },
              { value: "2", label: "2" },
              { value: "3", label: "3" },
              { value: "4", label: "4" },
              { value: "5", label: "5 - Medium" },
              { value: "6", label: "6" },
              { value: "7", label: "7" },
              { value: "8", label: "8" },
              { value: "9", label: "9" },
              { value: "10", label: "10 - High" },
            ]}
          />
        </div>
      </Modal>
    </>
  );
};

export default MonitorAlertDetail;
