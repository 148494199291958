import { NavLink } from "react-router-dom";

// This is the beginnigs of shared components for page layouts
// TODO: define these in the design and use them in other parts of the app

const ContentLayout = ({ children }: { children: React.ReactNode }) => {
  return <div className="mr-4">{children}</div>;
};

const Header = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex pt-4 pb-3 justify-between">{children}</div>;
};

const HeaderTitle = ({
  category,
  categoryLink,
  pageTitle,
  detail,
  children,
}: {
  category: string;
  categoryLink: string;
  pageTitle: string;
  detail?: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="text-heading1 text-space50 flex items-center">
      <div className="flex flex-col">
        <div className="flex items-center">
          <NavLink to={categoryLink}>{category}</NavLink>
          <span className="mx-1">/</span>
          <span>{pageTitle}</span>
        </div>
        {detail && <span className="text-xs text-space70">{detail}</span>}
      </div>
      <div className="ml-4">{children}</div>
    </div>
  );
};

const HeaderDivider = () => {
  return <hr />;
};

const PageContent = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex flex-col gap-4 h-full">{children}</div>;
};

export { ContentLayout, Header, HeaderDivider, HeaderTitle, PageContent };
