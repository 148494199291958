import React from "react";

interface SelectFieldProps {
  label: string;
  value: string;
  options: Array<{ value: string; label: string }>;
  onChange?: (value: string) => void;
  disabled?: boolean;
  error?: string;
  required?: boolean;
  placeholder?: string;
  className?: string;
  name?: string;
  id?: string;
}

const SelectField = ({
  label,
  value,
  options,
  onChange = () => {},
  disabled = false,
  error,
  required = false,
  placeholder = "Select an option",
  className = "",
  name,
  id,
}: SelectFieldProps) => (
  <div
    className={`p-3 rounded-lg outline outline-2 outline-offset-[-2px] outline-space90 inline-flex justify-start items-center w-full ${error ? "outline-red-500" : ""} ${className}`}
  >
    <div className="flex-1 inline-flex flex-col justify-start items-start">
      <div className="self-stretch justify-start text-space70 text-[10px] font-normal">
        {label}
        {required && <span className="text-red-500 ml-1">*</span>}
      </div>
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        required={required}
        name={name}
        id={id}
        className={`w-full justify-start text-space50 text-sm font-medium leading-none bg-transparent border-none focus:outline-none disabled:opacity-50 ${error ? "text-red-500" : ""}`}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
    {error && <div className="text-red-500 text-xs mt-1">{error}</div>}
  </div>
);

export default SelectField;
