import { useAuthFetch } from "context/AuthContext";
import type { Dayjs } from "dayjs";
import { BASE_URL, type MonitorData, formatDate } from "../enterprise";

export type MetricsConfig = {
  metricName: string;
  missingDataBehavior: "NO_INFILL" | "INFILL_ZERO" | "INFILL_LAST_VALUE";
};

export type VariablesConfig = {
  name: string;
  expression: string;
};

export type AlertConfig = {
  alertId: string | undefined;

  alertName: string;
  state: "UNHEALTHY" | "HEALTHY" | "WARNING";
  message: string;
  severity: string;
  condition: string;
  integrationIds: string[];
  metadata: Record<string, string>;
};

export type MonitorRequiredFields = {
  partnerId: string;
  placeId: string;
  resourceType: string;
  resourceId: string;

  monitorName: string;
  monitorLanguage: "DYNAMIC_EXPRESSO"; // TODO: add?
  frequency: string; // m, h, or d as the unit and a valid number before the unit
  lookbackPeriod: string; // m, h, or d as the unit and a valid number before the unit

  metrics: MetricsConfig[];
  variables: VariablesConfig[];
  alertConfigs: AlertConfig[];

  metadata: Record<string, string>;
};

export type Monitor = MonitorRequiredFields & {
  isEnabled: boolean; // should this be in the required fields?

  monitorId: string; // can be null for new monitors
  version: number;
  createdAt: string; // "2025-02-10T14:38:21.978-05:00",
  createdBy: string;

  state: "ACTIVE" | "INACTIVE"; // TODO: fix type
  dataState: "ACTIVE" | "INACTIVE"; // TODO: fix type
};

export type MonitorListResponse = {
  items: Monitor[];
  continuationToken?: string;
  hasMore: boolean;
};

export const useMonitorsApi = () => {
  const { authFetch } = useAuthFetch();

  const getMonitors = async (
    partnerId: string,
    continuationToken?: string,
    limit?: number,
  ): Promise<MonitorListResponse> => {
    const params = new URLSearchParams();
    params.set("partnerId", partnerId);
    if (continuationToken) {
      params.set("continuationToken", continuationToken);
    }
    if (limit) {
      params.set("limit", limit.toString());
    }

    const url = `${BASE_URL}/v1/monitors?${params.toString()}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => res.json());
  };

  const getMonitorForThing = async (
    thingId: string,
    monitorId: string,
    partnerId: string,
  ): Promise<Monitor> => {
    const params = new URLSearchParams();
    params.set("partnerId", partnerId);

    const url = `${BASE_URL}/v1/monitors/things/${thingId}/${monitorId}?${params.toString()}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => res.json());
  };

  const updateMonitorForThing = async (
    thingId: string,
    monitorId: string,
    partnerId: string,
    monitor: Monitor,
  ): Promise<Monitor> => {
    const params = new URLSearchParams();
    params.set("partnerId", partnerId);

    const url = `${BASE_URL}/v1/monitors/things/${thingId}/${monitorId}?${params.toString()}`;
    return await authFetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(monitor),
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((json) => {
          throw json;
        });
      }
      return res.json();
    });
  };

  const deleteMonitorForThing = async (
    thingId: string,
    monitorId: string,
    partnerId: string,
  ): Promise<void> => {
    const params = new URLSearchParams();
    params.set("partnerId", partnerId);

    const url = `${BASE_URL}/v1/monitors/things/${thingId}/${monitorId}?${params.toString()}`;
    await authFetch(url, {
      method: "DELETE",
    }).then((res) => {
      if (!res.ok) {
        return res.json().then((json) => {
          throw json;
        });
      }
    });
  };
  const getMonitorDataForPlace = async (
    placeId: string,
    placeType: string,
    resourceId: string,
    monitorId: string,
    partnerId: string,
    start: Dayjs,
    end: Dayjs,
  ): Promise<MonitorData> => {
    const startFmtd = formatDate(start);
    const endFmtd = formatDate(end);

    const params = new URLSearchParams();
    params.set("partnerId", partnerId);
    params.set("startTime", startFmtd);
    params.set("endTime", endFmtd);
    params.set("resourceId", resourceId);
    params.set("monitorId", monitorId);

    const url = `${BASE_URL}/v1/monitors/data/${placeType}/${placeId}?${params.toString()}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => res.json());
  };

  return {
    getMonitors,
    getMonitorForThing,
    updateMonitorForThing,
    deleteMonitorForThing,
    getMonitorDataForPlace,
  };
};
