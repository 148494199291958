import React from "react";
import {
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import type { MonitorData } from "../../api/enterprise";
import type { AlertConfig } from "../../api/enterprise/monitors";

type AlertState =
  | "UNHEALTHY"
  | "WARNING"
  | "HEALTHY"
  | "MISSING_DATA"
  | "NO_DATA";

const ALERT_STATE_COLORS: Record<AlertState, string> = {
  UNHEALTHY: "#EF4444", // red
  WARNING: "#F97316", // orange
  HEALTHY: "#22C55E", // green
  MISSING_DATA: "#9CA3AF", // grey
  NO_DATA: "#DDDDDD", // light grey
};

type AlertEvent = {
  time: number;
  alertId: string;
  alertState: AlertState;
  alertMessage: string;
  alertSeverity: string;
};

const MonitorAlertChart = ({
  alertConfigs,
  monitorData,
}: {
  alertConfigs: AlertConfig[];
  monitorData: MonitorData[];
}) => {
  const alertIds = Array.from(
    new Set(
      monitorData?.flatMap((data) =>
        data.alertEvents.map((event) => event.alertId),
      ),
    ),
  ).sort();

  // Create a mapping of alertId to y-axis position
  const alertIdToY = Object.fromEntries(
    alertIds.map((id, index) => [id, index]),
  );

  // Group alerts by state and maintain alertId for y-axis positioning
  const alertsByState =
    monitorData?.reduce(
      (acc, data) => {
        for (const event of data.alertEvents) {
          if (!acc[event.alertState]) {
            acc[event.alertState] = [];
          }
          acc[event.alertState].push({
            time: new Date(event.time).getTime(),
            alertState: event.alertState as AlertState,
            alertMessage: event.alertMessage,
            alertId: event.alertId,
            alertSeverity: event.alertSeverity,
            y: alertIdToY[event.alertId] ?? -1,
          });
        }
        return acc;
      },
      {} as Record<AlertState, (AlertEvent & { y: number })[]>,
    ) ?? {};

  return (
    <div className="w-full h-[300px]">
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart margin={{ top: 0, right: 20, bottom: 20, left: 0 }}>
          <XAxis
            dataKey="time"
            type="number"
            domain={["dataMin", "dataMax"]}
            tickFormatter={(time: number) =>
              new Date(time).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            }
            name="Time"
            tickCount={6}
            padding={{ left: 10, right: 10 }}
          />
          <YAxis
            dataKey="y"
            type="number"
            domain={[-1, alertIds.length]}
            ticks={alertIds.map((_, i) => i)}
            tickFormatter={(value: number) =>
              alertConfigs.find(
                (condition) => condition.alertId === alertIds[value],
              )?.alertName ?? alertIds[value].slice(-4)
            }
            name="Alert ID"
          />
          <ZAxis
            dataKey="alertSeverity"
            name="Severity"
            type="number"
            range={[20, 100]}
          />

          <Legend
            verticalAlign="bottom"
            height={36}
            content={({ payload }) => (
              <div className="flex flex-wrap gap-4 justify-center">
                {payload?.map((entry) => (
                  <div key={entry.value} className="flex items-center gap-2">
                    <div
                      className="w-3 h-3"
                      style={{ backgroundColor: entry.color }}
                    />
                    <span className="text-sm">{entry.value}</span>
                  </div>
                ))}
              </div>
            )}
          />

          <Tooltip
            formatter={(value: number | string, name: string) => {
              if (name === "time") {
                return new Date(value as number).toLocaleString();
              }
              return value;
            }}
            labelFormatter={(label: string) => `Alert ID: ${label}`}
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                const data = payload[0].payload as AlertEvent;
                return (
                  <div className="bg-white p-2 border border-gray-200 rounded shadow-sm">
                    <p className="font-medium">Alert ID: {data.alertId}</p>
                    <p className="text-sm text-gray-600">
                      Time: {new Date(data.time).toLocaleString()}
                    </p>
                    <p className="text-sm text-gray-600">
                      State: {data.alertState}
                    </p>
                    <p className="text-sm text-gray-600">
                      Message: {data.alertMessage}
                    </p>
                    <p className="text-sm text-gray-600">
                      Severity: {data.alertSeverity}
                    </p>
                  </div>
                );
              }
              return null;
            }}
          />
          {Object.entries(ALERT_STATE_COLORS).map(([state, color]) => (
            <Scatter
              key={state}
              data={alertsByState[state as AlertState]}
              name={state}
              fill={color}
              stroke="none"
            />
          ))}
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MonitorAlertChart;
