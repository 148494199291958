import { useAuth } from "context/AuthContext";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { type Monitor, useMonitorsApi } from "../../api/enterprise/monitors";
import type { ErrorType } from "../../api/shared";
import CreateMonitorModal from "../../components/monitors/CreateMonitorModal";
import ButtonComponent from "../../components/uikit/button";

const MonitorsPage = () => {
  const [monitors, setMonitors] = useState<Monitor[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<ErrorType | null>(null);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { user } = useAuth();
  const { getMonitors } = useMonitorsApi();

  // biome-ignore lint/correctness/useExhaustiveDependencies: getMonitors is not a dependency
  useEffect(() => {
    const fetchMonitors = async () => {
      try {
        const tempMonitors: Monitor[] = [];

        let hasMore = true;
        let continuationToken: string | undefined = undefined;

        while (hasMore) {
          const monitors = await getMonitors(user.partnerId, continuationToken);
          tempMonitors.push(...monitors.items);
          hasMore = monitors.hasMore;
          continuationToken = monitors.continuationToken ?? undefined;
        }

        setMonitors(tempMonitors);
        setIsLoading(false);
      } catch (err) {
        setError(err as ErrorType);
        setIsLoading(false);
      }
    };

    if (user.partnerId) {
      fetchMonitors();
    }
  }, [user.partnerId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h1>Monitors</h1>
        <ButtonComponent.Pill
          buttonStyle="primary"
          onClick={() => {
            setIsCreateModalOpen(true);
          }}
        >
          Create Monitor
        </ButtonComponent.Pill>
      </div>

      {error && (
        <div className="flex justify-center items-center my-4">
          <p className="text-red50 text-sm">
            {error.title || "Error loading monitors"}: {error.message}
          </p>
        </div>
      )}

      {monitors.map((monitor) => (
        // TODO: doesnt work for monitors on places
        <div key={monitor.monitorId}>
          <Link to={`/monitors/${monitor.resourceId}/${monitor.monitorId}`}>
            {monitor.monitorName} &rarr;
          </Link>
        </div>
      ))}

      <CreateMonitorModal
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      />
    </div>
  );
};

export { MonitorsPage };
